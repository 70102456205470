import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import TestimonialSlider from "../../components/sliders/sap-testimonial-slider/sap-testimonial-slider"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ClientsSlider from "../../components/sliders/clients-slider/clients-slider"

const DigitalInfrastructure = ({ data }) => {
  const images = {
    digitalBg: convertToBgImage(getImage(data.digitalBg)),
    what_client_says: convertToBgImage(getImage(data.what_client_says)),
    VertivSolution: convertToBgImage(getImage(data.VertivSolution)),
    SuccessStories: convertToBgImage(getImage(data.SuccessStories)),
  }
  const clientImages = {
    alliance: getImage(data.alliance),
    brandix: getImage(data.brandix),
    ca: getImage(data.ca),
    cing: getImage(data.cing),
    cse: getImage(data.cse),
    dimo: getImage(data.dimo),
    fair: getImage(data.fair),
    hela: getImage(data.hela),
    lakjaya: getImage(data.lakjaya),
    logo: getImage(data.logo),
    maga: getImage(data.maga),
    mcb: getImage(data.mcb),
    orange: getImage(data.orange),
    siyapatha: getImage(data.siyapatha),
    slt: getImage(data.slt),
    ucsc: getImage(data.ucsc),
    united: getImage(data.united),
    aia: getImage(data.aia),
    adani: getImage(data.adani),
  }

  const testimonialsImages = {
    samuel: getImage(data.samuel),
    viraj: getImage(data.viraj),
    vidunath: getImage(data.vidunath),
    minhaj: getImage(data.minhaj),
    gayan: getImage(data.gayan),
  }

  const testimoniallogo = {
    vertiv: getImage(data.vertiv),
  }

  const testimonial_slider = [
    {
      index: 0,
      image: testimonialsImages.samuel,
      name: "Samuel Sathiyadaran",
      logo: testimoniallogo.vertiv,
      position: "Information Technology Consultant",
      company: "Alliance Finance Company PLC",
      description:
        "Our association with Tech Pacific started back in 2016, when we required maintenance for our Blade Center RACK. Since then, Tech pacific has carried out our maintenance with superior quality along with technical assistance offered at request. Their high standards in professionalism, quality, consistent on time delivery, and willingness to negotiate renewals is the reason behind Alliance Finance selecting Tech Pacific time and again.",
    },
    {
      index: 1,
      image: testimonialsImages.vidunath,
      name: "Vidunath Mathagaweera",
      logo: testimoniallogo.vertiv,
      position: "Assistant Manager",
      company: "FairFirst Insurance Ltd",
      description:
        "We chose Tech Pacific to be our partner on D/Emerson Smart Rack Servicing and maintenance in 2018, and working with their team has been quite the experience. They have an extremely professional technical team, consisting of highly skilled workers. Their attention to detail and on-time delivery have consistently stood out, and we will continue to lean on Tech Pacific for their services.",
    },
    {
      index: 2,
      image: testimonialsImages.viraj,
      name: "Viraj Liyanage",
      logo: testimoniallogo.vertiv,
      position: "Manager Systems Development",
      company: "MAGA ENGINEERING PVT LTD",
      description:
        "Tech Pacific was responsible for the successful installation of our Data Center Infrastructure back in 2015. What truly stood out to us was the support offered even after providing the solution. We would highly recommend Techpac for their superior products, after sales support and professional staff; having access to all levels of management when required was an unexpected value addition.",
    },
    {
      index: 3,
      image: testimonialsImages.minhaj,
      name: "Minhaj Hussain",
      logo: testimoniallogo.vertiv,
      position: "IT Manager",
      company: "Lakjaya Micro Finance LTD",
      description:
        "Our relationship with Tech Pacific began in 2018, with the installation of a Network Smartrack Solution. We were pleasantly surprised by their team of specialists equipped with strong technical expertise. Tech Pacific’s timely responsiveness in providing solutions to our problems was a pleasure only surpassed by their professionalism in customer care.",
    },
    {
      index: 4,
      image: testimonialsImages.gayan,
      name: "Gayan Perera",
      logo: testimoniallogo.vertiv,
      position: "Deputy Manager IT",
      company: "Siyapatha Finance PLC",
      description:
        "We began our journey with Tech Pacific back in 2016 when purchasing two Emerson Smart Server Cabinets. Our experience over five years has been consistently supportive.Mr. Farzan, Mr. Sasanka, Mr. Chanaka, and the entire team have surpassed our initial expectations. The after-sales service is unparalleled because they attend to breakdowns without delay irrespective of day and night. We would highly recommend Tech Pacific.",
    },
  ]

  const clients_slider = [
    {
      index: 0,
      image: clientImages.alliance,
    },
    {
      index: 1,
      image: clientImages.united,
    },
    {
      index: 2,
      image: clientImages.brandix,
    },
    {
      index: 3,
      image: clientImages.ca,
    },
    {
      index: 4,
      image: clientImages.cing,
    },
    {
      index: 5,
      image: clientImages.cse,
    },
    {
      index: 6,
      image: clientImages.dimo,
    },
    {
      index: 7,
      image: clientImages.fair,
    },
    {
      index: 8,
      image: clientImages.hela,
    },
    {
      index: 9,
      image: clientImages.lakjaya,
    },
    {
      index: 10,
      image: clientImages.logo,
    },
    {
      index: 11,
      image: clientImages.maga,
    },
    {
      index: 12,
      image: clientImages.mcb,
    },
    {
      index: 13,
      image: clientImages.orange,
    },
    {
      index: 14,
      image: clientImages.siyapatha,
    },
    {
      index: 15,
      image: clientImages.slt,
    },
    {
      index: 16,
      image: clientImages.ucsc,
    },
    {
      index: 17,
      image: clientImages.aia,
    },
    {
      index: 18,
      image: clientImages.adani,
    },
  ]

  return (
    <Layout>
      <Seo title="Digital Infrastructure | Tech Pacific Lanka" />
      <section>
        <div className="main">
          <BackgroundImage {...images.digitalBg} className="sap-header">
            <div className="container">
              <div className="sap-header-content">
                <div className="img">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/products/digital-infrastructure/LOGO.jpg"
                    width={200}
                    alt="techpac"
                  />
                </div>
                <div className="header-description mt-3">
                  Tech Pacific has over a decade of experience in successfully
                  enabling businesses to evolve faster with tailored deployment
                  of its portfolio of Vertiv products and services by its team
                  of highly qualified and experienced technical staff
                </div>
                <div className="header-buttons mt-4 d-flex">
                  <Link to="/contact">
                    <button>Connect</button>
                  </Link>
                  <a href="tel:1234567890">
                    <button>Call Now</button>
                  </a>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="digital-header-section">
          <div className="container">
            <div className="digital-title text-center">
              Digital Infrastructure
            </div>
            <div className="digital-description text-center">
              Digital Infrastructure is essentially the foundation that enables
              Digital Transformation; a collection of technologies that perform
              as a base for Digital Technology operations of a business.
              Different organizations will contain a range of infrastructure
              including Software Systems, Internet Backbone, Broadband, Data
              Centers, Network Infrastructure, Cloud based platforms,
              Applications, APIs and more.
            </div>
          </div>
        </div>
      </section>
      <section>
        <BackgroundImage
          {...images.what_client_says}
          className="sap-testimonials about-testimonials"
        >
          <div className="container">
            <div className="sap-testimonials-title about-testimonials-title">
              What Clients Say
            </div>
            <div className="digital-testimonial-description">
              Find out how Tech Pacific has helped clients understand their
              needs and provided scalable Vertiv solutions that take into
              consideration their space, energy and budgetary concerns without
              compromising on performance, safety or quality.
            </div>
            <Link to="/contact">
              <button>Talk to us</button>
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <div className="swiper-button-prev1"></div>
            <div className="testimonial-super-main">
              <div className="testimonial-item-main">
                <TestimonialSlider slides={testimonial_slider} />
              </div>
            </div>
            <div className="swiper-button-next1"></div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <BackgroundImage
          {...images.VertivSolution}
          className="digital-info-section digital-header-section"
        >
          <div className="container">
            <div className="digital-title mb-4">Vertiv Smart Solutions</div>
            <div className="digital-description">
              Tech Pacific offers a collection of Digital Infrastructure
              solutions powered by Vertiv. Agility takes center stage in today’s
              business arena alongside availability. Vertiv enables peak
              flexibility, efficiency and speed through smarter equipment,
              intelligent controls and integrated solutions. These are
              configured to business requirements and include standardized,
              fully integrated racks, semi-custom integrated data rooms and
              customized hyperscale data center sites. Tech Pacific works
              together with clients to ensure convenient deployment and
              management of both individual products and complete systems. These
              include discrete software systems and software platform TrellisTM,
              aimed at monitoring and managing data center and communication
              network environments. Tech Pacific aims to improve existing
              infrastructure through Vertiv, making necessary additions for
              progress and business growth.
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="sap-grid-main">
          <div className="grid-row d-flex">
            <div
              className="grid-item digital-grid-item light"
              id="SmartCabinet"
            >
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title digital-success-title ">
                    SmartCabinet
                  </span>
                  <div className="digital-product-image pt-4">
                    <StaticImage
                      src="../../images/products/digital-infrastructure/Smart_Rows.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <span className="sap-grid-description">
                    The SmartCabinet by Vertiv enables Enterprise Class IT
                    infrastructure via integrated enclosure, power, cooling,
                    service and 360° visibility of all system components,
                    allowing for real-time monitoring, review and insight into
                    system performance.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <a
                    href="https://www.vertiv.com/en-in/campaigns/vertiv-smartcabinet/"
                    target="_blacnk"
                    rel="noreferrer"
                  >
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </a>
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="digital-button">Connect Now</button>
                    </Link>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="grid-item digital-grid-item d-flex dark"
              id="SmartRow"
            >
              <div className="smart-row mt-auto">
                <div className="d-flex">
                  <div className="grid-item-content">
                    <div className="sap-grid-details">
                      <span className="sap-grid-title digital-success-title">
                        SmartRow
                      </span>
                      <span className="sap-grid-description ">
                        SmartRow from Vertiv is a unique, fully-integrated,
                        room-neutral solution that allows you to deploy a
                        complete micro data center in just weeks. Configure upto
                        14 racks with data center thermal management, power
                        backup and management, fire suppression and cable
                        management.
                      </span>
                    </div>
                    <div className="sap-grid-footer cabinet-learnmore-section d-flex align-items-center">
                      <a
                        href="https://www.vertiv.com/en-in/campaigns/smartrow-dcr/"
                        target="_blacnk"
                        rel="noreferrer"
                      >
                        <div className="learn-more sap-learn-more">
                          LEARN MORE
                          <div className="icon">
                            <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                          </div>
                        </div>
                      </a>
                      <Link to="/contact">
                        <button className="digital-button">Connect Now</button>
                      </Link>
                    </div>
                  </div>
                  <div className="image cabinet">
                    {" "}
                    <StaticImage
                      src="../../images/products/digital-infrastructure/Smart_cabinat.png"
                      width={150}
                      alt="techpac"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-row d-flex">
            <div
              className="grid-item digital-padding-item dark"
              id="SmartSolutionsAndServerRooms"
            >
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title digital-success-title">
                    Annual Maintenance Contracts - Smart Solutions & Server
                    Rooms
                  </span>
                  <span className="sap-grid-description">
                    Tech Pacific’s maintenance services ensure the continued
                    health of your critical digital infrastructure in the
                    post-warranty period, avoiding unexpected downtime and
                    enabling business continuity. Choose from labor-only and
                    comprehensive contracts.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <div className="learn-more sap-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                  <Link to="/contact">
                    <button className="digital-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="grid-item digital-padding-item light"
              id="GreenfieldAndBrownfieldConventionalDataCentersAndServerRooms"
            >
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title digital-success-title">
                    Greenfield & Brownfield Conventional Data Centers & Server
                    Rooms
                  </span>
                  <span className="sap-grid-description">
                    Tech Pacific provides comprehensive solutions to design new
                    or expand existing server rooms and data centers. Our
                    critical power, thermal and infrastructure solutions help
                    operators achieve maximum server room efficiency and data
                    center uptime within prescribed budgets.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <div className="learn-more sap-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                  <Link to="/contact">
                    <button className="digital-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="digital-clients">
          <div className="">
            <div className="digital-client-title container">
              Curated Clientele
            </div>
            <div className="d-flex align-items-center">
              <div className="swiper-button-prev1"></div>
              <div className="client-slider-item">
                <ClientsSlider slides={clients_slider} />
              </div>
              <div className="swiper-button-next1"></div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <BackgroundImage {...images.SuccessStories} className="mid-screen">
          <div className="mid-screen-content container">
            <span className="mid-screen-title digital-success-title">
              Success Stories
            </span>
            <span className="mid-screen-description">
              Brief Description on Success Stories and Vertiv (Will be sent
              after we receive Success stories from Tech Pac){" "}
            </span>
            <Link to="/success-stories">
              <div className="learn-more">
                SEE MORE
                <div className="icon">
                  <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                </div>
              </div>
            </Link>
          </div>
        </BackgroundImage>
      </section> */}
      <section>
        <div className="get-in-touch blue orange d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button
                className="btn btn-primary blue-contact-footer"
                type="button"
              >
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query DigitalInfrastructure {
    digitalBg: file(
      relativePath: { eq: "products/digital-infrastructure/digitalBg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    what_client_says: file(relativePath: { eq: "about/what_client_says.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    VertivSolution: file(
      relativePath: { eq: "products/digital-infrastructure/VertivSolution.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    SuccessStories: file(
      relativePath: { eq: "products/digital-infrastructure/SuccessStories.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    alliance: file(
      relativePath: {
        eq: "products/digital-infrastructure/clients/alliance.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    brandix: file(
      relativePath: {
        eq: "products/digital-infrastructure/clients/brandix.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ca: file(
      relativePath: { eq: "products/digital-infrastructure/clients/ca.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cing: file(
      relativePath: { eq: "products/digital-infrastructure/clients/cing.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cse: file(
      relativePath: { eq: "products/digital-infrastructure/clients/cse.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dimo: file(
      relativePath: { eq: "products/digital-infrastructure/clients/dimo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    fair: file(
      relativePath: { eq: "products/digital-infrastructure/clients/fair.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hela: file(
      relativePath: { eq: "products/digital-infrastructure/clients/hela.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lakjaya: file(
      relativePath: {
        eq: "products/digital-infrastructure/clients/lakjaya.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    logo: file(
      relativePath: { eq: "products/digital-infrastructure/clients/logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    maga: file(
      relativePath: { eq: "products/digital-infrastructure/clients/maga.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mcb: file(
      relativePath: { eq: "products/digital-infrastructure/clients/mcb.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    orange: file(
      relativePath: { eq: "products/digital-infrastructure/clients/orange.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    siyapatha: file(
      relativePath: {
        eq: "products/digital-infrastructure/clients/siyapatha.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    slt: file(
      relativePath: { eq: "products/digital-infrastructure/clients/slt.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ucsc: file(
      relativePath: { eq: "products/digital-infrastructure/clients/ucsc.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    united: file(
      relativePath: { eq: "products/digital-infrastructure/clients/united.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    samuel: file(relativePath: { eq: "testimonials/samuel.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    viraj: file(relativePath: { eq: "testimonials/viraj.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    minhaj: file(relativePath: { eq: "testimonials/minhaj.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gayan: file(relativePath: { eq: "testimonials/gayan.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    vidunath: file(relativePath: { eq: "testimonials/vidunath.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    vertiv: file(
      relativePath: { eq: "products/digital-infrastructure/LOGO.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    aia: file(
      relativePath: { eq: "products/digital-infrastructure/clients/aia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    adani: file(
      relativePath: { eq: "products/digital-infrastructure/clients/adani.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default DigitalInfrastructure
